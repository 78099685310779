import { Template } from './template';

export class ExternalContent {
  id: Number;
  contentOutputFileName: string;
  template: Template;
  isEdit: boolean;
  contentOutputFileNameEncode: string;
  jsonWeather: any;
  contentType: string;

  constructor(contentOutputFileName?: string) {
    this.contentOutputFileName = contentOutputFileName;
    this.isEdit = false;
  }
}
