import { Component, OnInit } from '@angular/core';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'menu-fuji-climber-management',
  templateUrl: './menu-fuji-climber-management.component.html',
  styleUrls: ['./menu-fuji-climber-management.component.scss']
})
export class MenuFujiClimberManagementComponent implements OnInit {
  FIELD_COMPONENT = FIELD_COMPONENT;
  MODULE_NAME = MODULE_NAME;
  isTabClimberLocationInformationComponent: boolean;
  isTabPushNotificationComponent: boolean;

  constructor(private dataService: DataService, private menuActionService: MenuActionService, private commonService: CommonService) {
    this.dataService.currentData.subscribe(data => {
      switch (data[0]) {
        case Constant.IS_TAB_CLIMBER_LOCATION_INFORMATION: {
          this.isTabClimberLocationInformationComponent = <boolean>data[1];
          break;
        }
        case Constant.IS_TAB_PUSH_NOTIFICATION: {
          this.isTabPushNotificationComponent = <boolean>data[1];
          break;
        }
      }
    });
  }

  ngOnInit(): void {}

  /**
   * execute action for clear setting in menu Edit
   * @param {string} moduleName current module name
   */
  clearSetting(moduleName: string) {
    this.menuActionService.clearSetting(moduleName);
  }

  /**
   * sortFilterTicket
   * @param moduleName
   */
  sortFilterTicket(moduleName: string) {
    this.menuActionService.sortAndFilter(moduleName);
  }
}
