export class ReservationDetail {
  reservationId: string;
  reservationVerifyCode: string;
  userId: string;
  reservationStatus: string;
  reservationStatusName: string;
  representativeName: string;
  phoneNumber: string;
  email: string;
  custom1Name: any;
  custom2Name: any;
  custom3Name: any;
  reservationNumberAdult: number;
  reservationNumberCustom1: number;
  reservationNumberCustom2: number;
  reservationNumberCustom3: number;
  createdBy: string;
  updatedBy: string;
  priceLabelCustom1: string;
  priceLabelCustom2: string;
  priceLabelCustom3: string;
  priceAdult: number;
  priceCustom1: number;
  priceCustom2: number;
  priceCustom3: number;
  totalPrice: number;
  reservationPriceDescription: any;
  reservationClassId: string;
  dynamicPriceType: string;
  displayStartAt: string;
  displayEndAt: string;
  reserveOn: string;
  tripId: string;
  name: any;
  stockPartitionDescription: any;
  reservationClassName: any;
  ticketDisplayCenter1: any;
  ticketDisplayCenter2: any;
  ticketDisplayReservationClass1: any;
  ticketDisplayReservationClass2: any;
  orderId: string;
  saleTicketId: number;
  saleTicketName: any;
  ticketId: number;
  ticketName: any;
  inUseOperation: number;
  sectionType: string;
  originStopName: any;
  destStopName: any;
  orderUsageStatus: string;
  languages: string;
  remarkAnswer: string;
  updatedAt: string;
  reservationForm: any;
  reservationFormAnswer: any[];
}
